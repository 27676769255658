// Based on http://johnsardine.com/freebies/dl-html-css/pretty-little-calendar-css3/
.calendar {
  color: rgba(0, 0, 0, .8);
  font-size: 1.4rem;
  padding: 10px 10px 48px 10px;
  background: #fff;
  // -webkit-box-shadow: 0 1px 5px rgba(0,0,0,0.3);
  // -moz-box-shadow: 0 1px 5px rgba(0,0,0,0.3);
  // box-shadow: 0 1px 5px rgba(0,0,0,0.3);

  &-container {
    position: absolute;
    z-index: 1;
    box-shadow: 0 2px 10px rgba(0,0,0,.2);
    table {
      display: block;
    }
  }

  &-close {
    position: absolute;
    bottom: 18px; right: 24px;
  }
  // a {
  //   color: #467b89;
  //   cursor: pointer;
  // }

  thead, tbody, tfoot {
    background: #fff;
    // tr {
    //   th:first-child,
    //   td:nth-child(2) {
    //     padding-left: 14px;
    //   }

    //   th:last-child, {
    //     padding-right: 14px;
    //   }
    // }
  }

  &-prev,
  &-next {
    width: 16px;
    height: 22px;
    margin: 0 0 0 10px;
    a {
      display: block;
      width: 100%;
      height: 100%;
      background-image: url(../images/prev.svg);
      background-repeat: no-repeat;
      background-position: 0 center;

      &[hidden] {
        display: none;
      }
    }
  }

  &-next {
    margin: 0 10px 0 0;
    a {
      background-image: url(../images/next.svg);
    }
  }

  &-month {
    display: flex;
    justify-content: space-between;
    background: #fff; //f9f9f9
    // color: white;
    color: inherit;
    font-weight: 500;
    font-size: 1.6rem;
    padding: 24px 12px;
    text-align: center;
    // font-weight: normal;
    text-transform: capitalize;
    // text-shadow: 0 -1px 0 #000;
    // -webkit-box-shadow: inset 0 1px 0 #484848, 0 -1px 5px rgba(0,0,0,0.3);
    // -moz-box-shadow: inset 0 1px 0 #484848, 0 -1px 5px rgba(0,0,0,0.3);
    // box-shadow: inset 0 1px 0 #484848, 0 -1px 5px rgba(0,0,0,0.3);
    // border: 1px solid #373737;
    border-bottom: 1px solid #e5e9ec;
    z-index: -99;
  }

  thead th {
    // font-size: 8px;
    // padding: 10px;
    // color: #666;
    text-transform: capitalize;
    // text-shadow: 0 1px 0 rgba(255,255,255,0.5);
    // border-bottom: 1px solid #bbbbbb;
    // background: #fff;
    // background: -webkit-gradient(linear, left top, left bottom, from(#f7f7f7), to(#f1f1f1));
    // background: -moz-linear-gradient(top,  #f7f7f7,  #f1f1f1);
    text-align: center;
    padding: 2px;
    span {
      // display: block;
      width: 40px;
      height: 40px;
      line-height: 40px;
    }
  }

  tbody {
    td {
      // color: #666;
      // text-shadow: 0 1px 0 rgba(255,255,255,0.5);
      // padding: 12px;
      text-align: center;
      padding: 2px;
      // font-weight: bold;
      // border: 1px solid;
      // border-color: #fff #bbbbbb #bbbbbb #fff;
      // background: #fff;
      // background: -webkit-gradient(linear, left top, left bottom, from(#ededed), to(#dedede));
      // background: -moz-linear-gradient(top,  #ededed,  #dedede);
      
      span, a {
        display: block;
        // padding: 10px;
        width: 40px;
        height: 40px;
        line-height: 40px;
      }

      a {
        border-radius: 50%;
        background: #3796ff;
        color: white;
        &:hover {
          text-decoration: none;
          background: #3187e5;
        }
      }
    }

    td.today {
      font-weight: bold;
      color: #000;
      // border: 1px solid #467b89;
      // text-shadow: 0 1px 0 rgba(0,0,0,0.3);
      // background: #3796ff;
      // border-radius: 50%;
      // background: -moz-radial-gradient(50% 50% 0deg,ellipse cover, #6eafbf, #569EB1);
      // background: -webkit-gradient(radial, 50% 50%, 0, 50% 50%, 20, from(#6eafbf), to(#569EB1));
      // span, a {
      //   background: #3796ff;
      //   border-radius: 50%;
      // }
    }

    td.pad {
      background: #f5f5f5;
      background: -webkit-gradient(linear, left top, left bottom, from(#f5f5f5), to(#ececec));
      background: -moz-linear-gradient(top,  #f5f5f5,  #ececec);
    }
  }

  tfoot {
    // color: #e0e0e0;
    // font-size: 12px;
    text-align: center;

    tr {
      background: #f5f5f5;
      background: -webkit-gradient(linear, left top, left bottom, from(#f5f5f5), to(#ececec));
      background: -moz-linear-gradient(top,  #f5f5f5,  #ececec);
    }

    td {
      // padding: 10px 10px
    }

    a {
      color: #666;
      text-shadow: 0 1px 0 rgba(255,255,255,0.5);
    }

    td.prev {
      text-align: left
    }

    td.next {
      text-align: right
    }
  }
}
