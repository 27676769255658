.search {
  a {
  cursor: pointer;
  }

  > ul {
    // margin-bottom: 30px;
  }

  &--pager {
    margin: 30px;

    ul li {
      display: inline-block;
      a:not([href]):not([tabindex]) {
        color: white;
      }
    }
  }

  &--versions {
    padding: 36px 24px 24px 0;
  }
}

blockquote {
  font-size: 1.5rem;
  padding: 12px;
  em {
    font-style: normal;
    font-weight: 700;
  }
}
