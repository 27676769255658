// Font-awesome
$fa-font-path: 'jspm_packages/npm/font-awesome@4.7.0/fonts';
@import 'jspm_packages/npm/font-awesome@4.7.0/scss/font-awesome';

@import 'jspm_packages/npm/bootstrap@4.0.0-beta.2/scss/bootstrap';

/**
 * Variables
 */

$red: #e83535; //google red
$red-400: #e44542;
$grey-lighter: #E8E9EA;
$grey-light: #F5F5F5;
$text: #58595b;
$grey-text: #A7A9AC;
$green: #15B247;
$blue: #3796ff;
$darkblue: #0a1b2e;

@import 'src/app/archive-browse/browse';
@import 'app/archive-browse/calendar';
@import 'src/app/archive-browse/donut';
@import 'src/app/archive-browse/header';
@import 'src/app/archive-browse/search';
@import 'src/app/archive-browse/snapshot';
@import 'src/app/home/home';

// Fonts
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700);

html {
  font-size: 10px;
  overflow-y: scroll;
}

body {
  font-family: 'Roboto';
  font-size: 1.5rem;
  font-weight: 400;
  color: rgba(black, 0.8);
  -webkit-font-smoothing: antialiased;
  background: #eceff1;
}

a {
  color: $blue;
  &:hover {
    color: #3187e5;
  }
}


/**
 * Headings
 */
h1, h2, h3, h4 {
  margin: 0;
  padding: 0;
  small {
    display: block;
    font-size: 12px;
    margin-top: 5px;
  }
}

h1, h2, h3 {
  font-family: inherit;
  font-weight: 300;
  margin: 0px 20px 20px 0px;
}

h1 {
  font-size: 2.4rem;
  a {
    color: rgba(black, .8);
    &:hover {
      color: rgba(black, .8);
      text-decoration: underline;
    }
  }
}

h2 {
  font-size: 2.4rem;
  font-weight: 500;
  color: rgba(black, .8);
  margin-top: 30px;
  padding: 0 0 15px 0;
  border-bottom: 1px solid rgba(0, 0, 0, .07);
}

.card-title {
  h1, h2, h3 {
    margin: 30px;
  }
}

.item-list {
  .btn-add {
    float: right;
    margin: 30px;
  }
}

.filter-search {
  padding: 30px;
  width: 100%;
  input {
    font-size: 14px;
    display: block;
    background: transparent;
    width: 100%;
    border: none;
    border-bottom: 1px solid $grey-text;
    padding:8px;
    width:100%;
    font-weight: 300;
    margin-bottom: 10px;
    &:focus {
      outline: none;
    }
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.dropdown-menu {
  font-size: inherit;
}

.archiwwwe-root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.main {
  flex: 1 0 auto;
  padding: 30px 15px 60px 15px;
  .page-snapshots & {
    padding: 0;
  }
  .page-archives & {
    padding: 0 0 60px 0;
  }
}

/* Login */
.login {
  margin-top: 50px;
  form {
    margin: 40px 80px;
  }

  button {
    margin-top: 10px;
  }
}

.btn {
  font-size: 15px;
  border-radius: 3px;
  padding: 12px 24px;
  background: #3796ff;
  font-weight: 500;
  color: white;
  &:hover {
    color: white;
    background: #3187E5;
    transition: background .3s ease-out;
  }
}

.btn-danger {
  background: #E54542;
  border: 1px solid transparent;
  &:hover {
    background: $red;
    border: 1px solid transparent;
  }
}

.btn-add {
  position: relative;
  padding: 8px 20px 8px 44px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-7px);
    width: 12px;
    height: 12px;
    background: url(../images/add.svg);
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.btn-small {
  padding: 6px 12px;
}

.btn-action {
  display: block;
  float: right;
  width: 19px;
  height: 19px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 15px auto;

  &.edit {
    background-image: url(../images/edit.svg);
    margin-right: 12px;
  }

  &.delete {
    background-image: url(../images/close.svg);
    margin-right: 4px;
  }
}

.btn-success {
  background: $green;
  border: 1px solid transparent;
  &:hover {
    background: #129C3E;
    border: 1px solid transparent;
  }
}

.form-group {
  margin-bottom: 24px;
}

.form-container {
  background: white;
}

.has-error {
  color: red;
}

.form-control.ng-touched.ng-invalid {
  border: 1px solid $red;
}

.checkbox {
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
}

.add-user-action,
.edit-profile {
  padding-top: 24px;
  padding-bottom: 24px;
  .btn {
    margin-left: 12px;
  }
}

.edit-profile {
  clear: both;
}

.large-number {
  font-size: 3.4rem;
  font-weight: 300;
  color: #41464D;
}

/**
 * Header
 */

.banner {
  background: $darkblue;
  padding: 10px 0;
}

.navbar {
  padding: 0.5rem 3rem;
}

.navbar-brand {
  margin-left: 4px;
  img {
    width: 140px;
    height: auto;
  }
}

.primary-nav {
  flex: 1;
  > ul {
    display: flex;
    float: right;
    margin: 0;
    padding: 0;
    list-style-type: none;
    min-height: 50px;
    li {
      position: relative;
      a {
        display: block;
        padding: 24px;
        &:hover {
          background: #3287E5;
        }
      }
    }
    li.open {
      > a {
        background: #3187e5;
      }
    }
    img {
      width: 25px;
    }
  }
}

.icon {
  display: inline-block;
  padding: 12px;
  img {
    width: 25px;
  }

  &-calendar {
    display: block;
    width: 25px;
    height: 28px;
    box-sizing: content-box;
    background-image: url(../images/calendar.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 25px 28px;
  }
}



/**
 * Tables
 */

.datatable-header-cell-label,
.datatable-body-cell-label {
  padding: 20px 30px;
}

.datatable-body-row {
  border-top: 1px solid rgba(black, .04);
}

.datatable-body {
  margin-top: 20px;

  .empty-row {
    padding: 0 0 30px 30px;
  }
}

.datatable-header-cell {
  color: rgba(0,0,0,0.3);
}

.datatable-body-row:hover {
  background: #f9f9f9;
  transition: background .3s ease-out;
}

.datatable .actions a {
  font-size: 1.1em;
}

/* Legacy tables */

.table {
  margin-bottom: 0;
}

.table-hover>tbody>tr:hover {
  background: #f9f9f9;
  transition: background .3s ease-out;
}

.table >thead {
  border: none;
}

.table > thead > tr > th {
  border-bottom: 1px solid rgba(black, .04);
  color: rgba(0,0,0,0.3);
  font-weight: 400;
}

.table >thead > tr > th {
  padding: 24px;
}

.table >tbody > tr > td {
  padding: 24px;
  border-top: 1px solid rgba(0,0,0,.04);
  border-bottom: 1px solid rgba(black, .04);
  vertical-align: middle;
  &:last-child {
  }
}

/**
 * Labels
 */

.label {
  font-size: 1.3rem;
  // font-weight: normal;
  padding: .3em 1em .4em 1em;
  border-radius: 8px;
}
.label-info {
  background-color: $red;
}

/**
 * Snapshot list
 */

.snapshots {
  overflow: hidden;
}

.snapshot-list {
  ul {
    margin-top: 12px;
    padding-top: 12px;
    border-top: 1px solid $grey-lighter;
  }

  li.active {
    a {
      // color: #000;
      font-weight: 700;
      background-color: #f5f5f5;
    }
  }

  a {
    display: block;
    color: $text;
    padding: 6px 0;
    background-image: url(../images/download.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 60%;
    &:hover {
    text-decoration: none;
    background-color: #f5f5f5;
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.loader {
  position: absolute;
  top: 50%; left: 50%;
  margin-top: -5em;
  margin-left: -2.5em;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 0.5em solid rgba(0, 0, 0, 0.2);
  border-right: 0.5em solid rgba(0, 0, 0, 0.2);
  border-bottom: 0.5em solid rgba(0, 0, 0, 0.2);
  border-left: 0.5em solid #eee;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/**
 * Footer
 */

.footer {
  flex-shrink: 0;
  background: white;
  padding: 24px 0;
}

.form-control {
  height: auto;
  padding: 12px;
  background-color: #fcfcfc;

  font-size: 15px;
  color: rgba(black, 0.8);
  border-width: 2px;
  border-color: #e7e7e7;
  border-radius: 3px;
  box-shadow: none;

  &:focus {
    border-color: rgb(59, 153, 252);
  }
}

// remove hack after this Bootstrap fix is released:
// https://github.com/twbs/bootstrap/issues/20809
select.form-control:not([size]):not([multiple]) {
  height: inherit;
}

.alert {
  padding: 12px;
  margin-bottom: 24px;
  border-radius: 3px;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(../images/dropdown.svg);
  background-position: calc(100% - 12px) center;
  background-repeat: no-repeat;
}

label {
  font-weight: 400;
  margin-bottom: 12px;
  margin-left: 4px;
}
