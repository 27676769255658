.donutchart {
  padding: 85px 0;

  &--tooltip {
    font-size: 36px;
    font-weight: 500;
    fill: #4d4d4d;
    stroke: none;
  }

  .legend text {
    cursor: pointer;
  }
}
