.sub-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -30px -30px 30px -30px;
  padding: 12px 15px;
  background: #fff;
  border-bottom: 1px solid #e4e5e9;

  h1 {
    margin: 1px 6px 0 4px;
  }

  form {
    width: 25%;
    padding-left: 15px;
    input {
      border-color: #dcdce8;
      border-width: 1px;
    }
  }

  &--title-calendar {
    display: flex;
    align-items: center;
  }

  &--snapshot-date {
    font-weight: 300;
    margin: 20px 0;
  }

  &--indexing-message {
    color: $grey-text;
  }  
}
