.calendar-container {
  vertical-align: top;
  table {
    display: inline-block;
  }
}

.stats-summary {
  display: flex;
  flex-direction: column;
  .card {
    flex: 1;
    .card-body {
      display: flex;
      align-items: center;
      justify-content: center;
      .stats-item-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        h3 {
          margin: 0 30px;
        }
      }
    }
  }
}
